<script setup lang="ts">
const envelopes = [
  {
    translate: '81 41',
    delay: '-3105ms',
  },
  {
    translate: '270 23',
    delay: '-9903ms',
  },
  {
    translate: '198 268',
    delay: '-8163ms',
  },
  {
    translate: '315 383',
    delay: '-98ms',
  },
  {
    translate: '81 347',
    delay: '-2084ms',
  },
  {
    translate: '37 176',
    delay: '-9097ms',
  },
  {
    translate: '387 268',
    delay: '-231ms',
  },
  {
    translate: '379 133',
    delay: '-4860ms',
  },
  {
    translate: '207 142',
    delay: '-8657ms',
  },
]

const scrollPosition = ref(0)
const cloudsTranslation = computed(() => -scrollPosition.value * 0.35)

function onScroll() {
  scrollPosition.value = window.scrollY
}

onMounted(() => {
  window.addEventListener('scroll', onScroll)
})

onUnmounted(() => {
  window.removeEventListener('scroll', onScroll)
})
</script>

<template>
  <div class="hero">
    <div class="left-column">
      <div class="text">
        <slot />
      </div>
    </div>
    <div class="right-column">
      <div class="hero-visual-container">
        <svg
          class="clouds"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          version="1.1"
          viewBox="0 0 500 500"
          :style="{ transform: `translateY(${cloudsTranslation}px)` }"
        >
          <g style="clip-path: circle(225px at 250px 250px)">
            <image width="2048" height="2048" transform="scale(.24)" href="/img/clouds.webp" />
          </g>
        </svg>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          version="1.1"
          viewBox="0 0 500 500"
        >
          <defs>
            <image id="envelope" width="1266" height="786" href="/img/envelope.webp" />
          </defs>
          <g v-for="envelope of envelopes" :transform="`translate(${envelope.translate}) rotate(18) scale(0.075)`">
            <use class="envelope" :style="{ 'animation-delay': envelope.delay }" xlink:href="#envelope" />
          </g>
        </svg>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.hero {
  display: flex;
  gap: 40px;
  margin: -100px min(80px, 5vw) 0 min(80px, 5vw);
  height: 100vh;
  max-height: 1000px;
  font-size: 17px;
  justify-content: center;

  > * {
    display: flex;
    align-items: center;
  }

  .left-column {
    flex: 0 0 60%;
  }
  .right-column {
    flex: 1;
  }

  &:deep(h1) {
    font-size: clamp(30px, 6vw, 80px);
    margin-bottom: 80px;

    @media screen and (max-width: 600px) {
      font-size: max(30px, 11vw);
    }
  }

  @media screen and (max-width: 600px) {
    flex-direction: column-reverse;
    margin-top: 0;
    margin-bottom: 160px;
    gap: 0;
    height: auto;
  }
}

.hero-visual-container {
  width: 100%;
  position: relative;

  .clouds {
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    transition: transform 75ms linear;
  }
}

@keyframes upAndDown {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-300px);
  }
  100% {
    transform: translateY(0);
  }
}

.envelope {
  animation: upAndDown 10s ease-in-out infinite;
}
</style>
